/**
 * Created by benzvikler on 2017-04-15.
 */
(function () {

    $contentBox = $('.content-box');
    $contentWrappers = $('.content-wrapper').toArray();
    $modals = $('.work-modals').toArray();
    $submit = $('#form-submit');
    $clear = $('#form-clear');
    $formName = $('#form-name');
    $formEmail = $('#form-email');
    $formMessage = $('#form-message');

    $contentBox.on('click', function (event) {
        event.stopPropagation();
        event.stopImmediatePropagation();

        if ($(event.target).hasClass('corner-nav')) {
            var nav = $(event.target);
            var nextContentIndex = nav.data('content');
            var urlPathExtension = nav.data('url');
            var currentContent;

            // history.pushState({ title: "foo" }, nav.text, urlPathExtension);

            for (var i = 0; i < $contentWrappers.length; i++) {
                if ($($contentWrappers[i]).css('display') !== 'none') {
                    currentContent = $($contentWrappers[i]);
                }
                if (typeof(currentContent) !== 'undefined') {
                    currentContent.addClass('fade-out');
                    window.setTimeout(function () {
                        var nextContent = $($contentWrappers[nextContentIndex]);

                        currentContent.css('display', 'none');
                        currentContent.removeClass('fade-out');
                        nextContent.css('display', 'inherit');

                        nextContent.addClass('fade-in');
                        window.setTimeout(function () {
                            nextContent.removeClass('fade-in');
                        }, 450)
                    }, 450);
                }
            }
        }

        if ($(event.target).hasClass('work-cards')) {
           console.log("hi");
           var $card = $(event.target);
           var $modals = $('work-modals').toArray();
           var modalPos = ($card).data('dataModal').parseInt;
           $($modals[modalPos]).addClass('is-active');
        }
    });

    $('.work-cards').click(function (event) {
        var card = $(this);
        var modal = $($modals[parseInt(card.data('modal'))]);

        modal.addClass('is-active');
    });

    $('.modal-close').click(function () {
        var modal = $($(this).parent());
        modal.addClass('modal-shrink');
        window.setTimeout(function () {
            modal.removeClass('is-active');
            modal.removeClass('modal-shrink');
        }, 275);
    });

    $submit.click(function () {
        // should validate fields here
        var name = $formName.val();
        var email = $formEmail.val();
        var message = $formMessage.val();

        console.log(name, email, message);

        $.ajax({
            url: 'http://formspree.io/bzvikler@gmail.com',
            method: 'POST',
            data: { "name": name, "email": email, "message": message },
            dataType: 'json',
            beforeSend: function() { /* sending message... */
                console.log("sending");
            },
            success: function(data) { /* success message... */
                console.log("that worked");
            },
            error: function(err) { /* error message... */
                console.log("that did not work");
            }
        });
    });

    function makeVisibile(elem) {
        $(elem).css('display', 'inherit');
    }

    function makeHidden(elem) {
        $(elem).css('display', 'none');
    }

}).call(this);
